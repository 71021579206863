import { DataPoint } from "../../utils/prometheus";
import { useEffect, useState } from "react";
import { ParentSize } from "@visx/responsive";
import ApexChart from "react-apexcharts";
import { renderToString } from "react-dom/server";
import { ApexOptions } from "apexcharts";
import { NumberSeriesProps, StrokeStyle } from "./Graph";
import AnnotationLabel from "./AnnotationLabel";
import EmptyGraph from "./EmptyGraph";
import { Loader } from "./Loader";
import { LineLegend } from "./GraphLegends";
import styles from "./styles/GraphLegends.module.scss";

export interface Props extends NumberSeriesProps {
    annotationLabelFormat?: (value: number) => string;
    configs: { [key: string]: LineConfig };
    yTickFormat?: (current: string) => string;
    yTickAutoscale?: boolean;
    data?: { [key: string]: DataPoint[] };
    type?: string;
    isLoaded?: boolean;
    showColumns?: boolean;
    hideTooltip?: boolean;
    onDragChange?: (chart: any, options: any) => void;
    hideLegend?: boolean;
    syncId?: string;
    graphId?: string;
}

export interface LineConfig {
    stroke: string;
    lineLabel: string;
    strokeStyle?: StrokeStyle;
    yAccessor?: (d: { y: number }) => number;
}

const convertToApexSeries = (props: Props) => {
    if (!props.data) {
        return [];
    }
    return Object.keys(props.data).map((key) => {
        return {
            name: key,
            data: props.data![key].map((point) => [point.x.getTime(), point.y]),
            color: props.configs[key]?.stroke || "#8474DC",
        };
    });
};

const convertToApexOptions = (props: Props): ApexOptions => {
    return {
        chart: {
            id: props.graphId,
            group: props.syncId,
            stacked: false,
            height: 350,
            zoom: {
                type: "x" as const,
                enabled: props.onDragChange ? true : false,
                autoScaleYaxis: true,
                allowMouseWheelZoom: false,
            },
            toolbar: {
                show: false,
                autoSelected: "zoom" as const,
            },
            animations: {
                enabled: false,
            },
            events: {
                zoomed: function (chartContext, { xaxis, yaxis }) {
                    props.onDragChange?.(chartContext, { xaxis, yaxis });
                },

                click: function (e, chart, opts) {
                    if (e.target.classList.contains("apexcharts-legend-text")) {
                        const seriesIndex = e.target.getAttribute("i");
                        const serieName =
                            opts.globals.seriesNames[seriesIndex as number];
                        const currentSeries = opts.config.series;
                        if (e.ctrlKey || e.metaKey) {
                            const newSeries = currentSeries.map((s: any) => ({
                                ...s,
                                hidden:
                                    s.name === serieName ? !s.hidden : s.hidden,
                            }));
                            chart.updateSeries(newSeries);
                        } else {
                            const newSeries = currentSeries.map((s: any) => ({
                                ...s,
                                hidden: s.name !== serieName,
                            }));
                            chart.updateSeries(newSeries);
                        }
                    }
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        markers: {
            size: props.type === "scatter" ? 3 : 0,
            strokeWidth: 0,
        },
        grid: {
            show: true,
            borderColor: "#E2E1EF",
            strokeDashArray: 2,
            xaxis: {
                lines: {
                    show: false,
                },
            },
            yaxis: {
                lines: {
                    show: true,
                },
            },
        },
        xaxis: {
            type: "datetime" as const,
            labels: {
                datetimeUTC: false,
                style: {
                    colors: "#E2E1EF",
                },
            },
            tooltip: {
                enabled: false,
            },
            axisBorder: {
                color: "#E2E1EF",
                strokeWidth: 0.5,
            },
        },
        stroke: {
            width: 2,
            dashArray: props.data
                ? Object.keys(props.data).map((key) => {
                      return props.configs[key]?.strokeStyle == "dotted"
                          ? 3
                          : 0;
                  })
                : undefined,
        },
        legend: {
            show: !props.hideLegend,
            position: "right" as const,
            fontSize: "14px",
            fontWeight: "500",
            fontFamily: `'Haskoy', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif`,
            markers: {
                customHTML: function () {
                    return renderToString(<LineLegend />);
                },
            },
            onItemClick: {
                toggleDataSeries: false,
            },
        },

        yaxis: {
            showAlways: true,
            forceNiceScale: true,
            labels: {
                formatter: (val: number) =>
                    props.yTickFormat?.(val.toString()) ?? val.toString(),
                style: {
                    colors: "#E2E1EF",
                },
            },
            axisBorder: {
                color: "#E2E1EF",
                width: 5,
            },
        },
        tooltip: {
            enabled: !props.hideTooltip,
            shared: false,
            custom: function (data) {
                return renderToString(
                    <AnnotationLabel
                        configs={props.configs}
                        labelFormatter={props.annotationLabelFormat}
                        {...data}
                    />
                );
            },
        },
    };
};

function MultiNumberSeries(props: Props): JSX.Element {
    const [data, setData] = useState<Props["data"]>(props.data);
    const [isDataLoaded, setIsDataLoaded] = useState(props.isLoaded);

    useEffect(() => {
        setData(props.data);
    }, [props.data]);

    useEffect(() => {
        setIsDataLoaded(props.isLoaded);
    }, [props.isLoaded]);

    const keys: string[] = Object.keys(props.configs);

    if (isEmptyData(data, keys) && isDataLoaded) {
        return (
            <EmptyGraph
                startDate={props.startDate}
                endDate={props.endDate}
                width={props.width}
                height={props.height}
            />
        );
    }

    return !isEmptyData(data, keys) ? (
        <>
            <div className={styles.chartContainer}>
                <div className={!isDataLoaded ? styles.loadingBar : ""} />
                <ApexChart
                    series={convertToApexSeries(props)}
                    options={convertToApexOptions(props)}
                    height={props.height}
                    width={props.width}
                    type={(props.type || "line") as any}
                />
            </div>
        </>
    ) : (
        <Loader height={props.height} />
    );
}

function isEmptyData(data: Props["data"], keys: string[]): boolean {
    if (!data) {
        return true;
    }

    return !keys.some((key) => key in data && data[key].length > 0);
}

const MultiNumberSeriesResponsive = (props: Props) => (
    <ParentSize>
        {({ width }) => <MultiNumberSeries {...props} width={width} />}
    </ParentSize>
);

export default MultiNumberSeriesResponsive;
