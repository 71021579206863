import useSWR from "swr";
import styles from "../styles/Ingestion.module.scss";
import { axiosFetcherWithParams, nFormatter } from "../../../utils/utils";
import { useParams } from "react-router-dom";
import { Dataset } from "../../../utils/types";

import RowsIngestedIcon from "icons/rows-ingested.svg";
import EmptyState from "../../EmptyState";
import { LastUpdatedTimestamp } from "./LatestTimestamp";

function DerivedDataStats({ dataset }: { dataset: Dataset }) {
    const datasetName = dataset.name;
    const { branchName } = useParams();
    const { data, isLoading } = useSWR(
        [
            "post",
            `/branch/${branchName}/metrics/current_dataset_size`,
            {
                dataset_name: datasetName,
            },
        ],
        axiosFetcherWithParams
    );

    return data && !isLoading ? (
        <div className={styles.ingestionWidget}>
            <div className={styles.ingestionLifetime}>
                <div className={styles.ingestionLifetimeRows}>
                    <div className={styles.ingesstionLifetimeRow}>
                        <div className={styles.ingestionLifetimeKey}>
                            <RowsIngestedIcon />
                            <div>Total Rows</div>
                        </div>
                        <div className={styles.ingestionLifetimeAbs}>
                            {data.data.result[0]
                                ? nFormatter(data.data.result[0].value[1], 3)
                                : "-"}{" "}
                            rows
                        </div>
                    </div>
                    <LastUpdatedTimestamp
                        dataset={dataset}
                        branchName={branchName || ""}
                    />
                </div>
            </div>
        </div>
    ) : (
        <EmptyState
            className={styles.derivedDataSection}
            loading={isLoading}
            spinnerSize="small"
            text={"There was a problem in fetching the latest data"}
        />
    );
}

export default DerivedDataStats;
